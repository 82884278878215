import { createRouter, createWebHistory } from 'vue-router';
import VPLogin from '../components/VPLogin.vue';
import VPUserArea from '../components/VPUserArea.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: VPLogin
  },
  {
    path: '/user',
    name: 'UserArea',
    component: VPUserArea
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
