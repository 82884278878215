<template>
  <div class="hero is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-5">
            <div class="box">
              <h1 class="title">Login</h1>
              <form @submit.prevent="login">
                <div class="field">
                <label class="label">Email</label>
                <div class="control">
                  <input class="input" type="email" v-model="email" placeholder="Email" name="email" autocomplete="email">
                </div>
              </div>
              <div class="field">
                <label class="label">Password</label>
                <div class="control">
                  <input class="input" type="password" v-model="password" placeholder="Password" name="password" autocomplete="current-password">
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button is-primary" type="submit">Login</button>
                </div>
              </div>                               </form>
              <p v-if="error" class="has-text-danger">{{ error }}</p>
            </div>
          </div>
          <div class="column">
            <h1 class="title is-2">Welcome to VirtualPatientGPT</h1>
            <p class="subtitle is-4">
              A platform designed for psychiatrists to practice and pass their CASC exam using AI-generated virtual patients.
            </p>
            <div class="columns is-multiline is-mobile">
              <div class="column is-half-desktop is-full-mobile">
                <div class="feature">
                  <h3 class="title is-5">Designed for CASC Exam</h3>
                  <p>Improve your skills and confidence to succeed in the CASC exam.</p>
                </div>
              </div>
              <div class="column is-half-desktop is-full-mobile">
                <div class="feature">
                  <h3 class="title is-5">Realistic Patient Interactions</h3>
                  <p>Gain experience with a wide variety of cases and conditions.</p>
                </div>
              </div>
              <div class="column is-half-desktop is-full-mobile">
                <div class="feature">
                  <h3 class="title is-5">Powered by GPT-4</h3>
                  <p>Benefit from the advanced language model to simulate realistic conversations.</p>
                </div>
              </div>
              <div class="column is-half-desktop is-full-mobile">
                <div class="feature">
                  <h3 class="title is-5">Customizable Scenarios</h3>
                  <p>Tailor virtual patient cases to your specific learning objectives.</p>
                </div>
              </div>
              <div class="column is-half-desktop is-full-mobile">
                <div class="feature">
                  <h3 class="title is-5">Accessible Anytime, Anywhere</h3>
                  <p>Use VirtualPatientGPT on any device with an internet connection.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { auth } from '../../firebase'; // Update the import statement

export default {
  setup() {
    const email = ref('');
    const password = ref('');
    const error = ref('');
    const router = useRouter(); // get the router instance

    const login = async () => {
      try {
        await auth.signInWithEmailAndPassword(email.value, password.value);
        router.push('/user');
        // Navigate to the protected user area after successful login
      } catch (err) {
        error.value = err.message;
      }
    };

    return {
      email,
      password,
      error,
      login
    };
  },
};
</script>

<style scoped>

  .hero:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .8;
  /* background-image: url('https://cdni.iconscout.com/illustration/premium/thumb/medical-pattern-2246332-1939311.png'); */
  background-image: url('@/assets/medpattern.png');
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
}

  .box {
    background-color: #f5f5f5;
    box-shadow: 0 0.5em 1em -0.125em rgba(108, 118, 134, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
  }

  .feature {
    padding: 1.5rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0.5rem;
    box-shadow: 0 0.5em 1em -0.125em rgba(108, 118, 134, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
    margin-bottom: 1rem;
  }

  .feature h3 {
    margin-bottom: 0.5rem;
  }
</style>