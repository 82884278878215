import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyA82jhpm81ScquL9d0GO3ca-4J5veA7Bv4",
  authDomain: "virtualpatientgpt.firebaseapp.com",
  projectId: "virtualpatientgpt",
  storageBucket: "virtualpatientgpt.appspot.com",
  messagingSenderId: "481826246023",
  appId: "1:481826246023:web:446291b7f531812032d722",
  measurementId: "G-71PPLNXVDY"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const auth = firebaseApp.auth();
const firestore = firebaseApp.firestore();

export { firebaseApp, auth, firestore };
