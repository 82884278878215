<template>
  <div class="main-container">
    <header class="header">
      <nav class="navbar is-dark">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" href="#">
              <h1 class="title is-4 has-text-light">VirtualPatientGPT</h1>
            </a>
            <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarMenu"
              @click="toggleMenu">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <template v-if="user">
            <div class="navbar-menu" :class="{ 'is-active': menuActive }" id="navbarMenu">
              <div class="navbar-end">
                <div class="navbar-item has-dropdown is-hoverable">
                  <a class="navbar-link"> <span class="icon">
                      <i class="fas fa-user"></i>
                    </span>{{ user.email }}</a>
                  <div class="navbar-dropdown">
                    <a class="navbar-item" @click="logout">  <span class="icon">
    <i class="fas fa-sign-out-alt"></i>
  </span>Logout</a>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </nav>
    </header>
    <select id="patient-selection" class="select" v-model="selectedPatient" @change="changePatient">
      <option v-for="(patient, index) in sortedPatients" :key="index" :value="patient.id">
        Patient {{ patient.id }}. {{ patient.name }}
      </option>
    </select>
    <main class="chat-container" ref="chatContainer">
      <div class="chat-box">
        <div v-for="(message, index) in messages" :key="index" class="message-container"
          :class="{ 'instructions': message.sender === 'instructions' }">
          <div :class="`message-${message.sender}`">{{ message.text }}</div>
        </div>
        <div class="message-container loading-container" v-if="loading">
          <img src="@/assets/loading.gif" alt="Loading" class="loading-gif" />
        </div>
      </div>
    </main>
    <footer class="footer">
      <div class="send-input-bar">
        <input class="input" type="text" placeholder="Type your message..." v-model="messageInput"
          @keyup.enter="sendMessage" />
        <button class="button is-info" @click="sendMessage">
          <span>Send</span>
          <span class="icon">
            <i class="fas fa-paper-plane"></i>
          </span>
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import { auth, firestore } from '../../firebase';

export default {
  data() {
    return {
      user: null,
      messages: [
      ],
      menuActive: false,
      messageInput: '',
      loading: false,
      selectedPatient: 1,
      patients: [
      ],
    };
  },
  computed: {
    sortedPatients() {
      return [...this.patients].sort((a, b) => a.id - b.id);
    },
  },
  async created() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      } else {
        this.$router.push('/');
      }
    });

    await this.fetchPatients();
  },
  methods: {
    async logout() {
      await auth.signOut();
      this.$router.push('/');
    },
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
    async changePatient() {
      console.log('Selected Patient:', this.selectedPatient);

      // Fetch the selected patient's data from Firebase
      const patientQuery = firestore.collection('patients').where('id', '==', this.selectedPatient);
      const patientQuerySnapshot = await patientQuery.get();

      // Check if the document exists and extract the data
      if (!patientQuerySnapshot.empty) {
        const patientDoc = patientQuerySnapshot.docs[0];
        const patientData = patientDoc.data();

        console.log('Fetched Patient Data:', patientData);

        // Update the conversation
        this.messages = [
          {
            sender: 'instructions',
            text: patientData.doctor_instructions + " - Preparation time: " + patientData.prep_time,
          },
          {
            sender: 'doctor',
            text: 'Help me study for my psychatry exam. You will play the role of the patients or their relatives, here are your instructions: ' +
              patientData.patient_instructions +
              'From now on, only reply as the patient or relative, (describe any non-verbal action from the patient/relative in parenthesis). Start the conversation saying a simple: hello doctor.'
          },
        ];

        // Send the "start" message and wait for the bot's first message
        this.messageInput = 'start';
        this.sendMessage();
      } else {
        console.error('No matching patient document found.');
      }
    },


    async fetchPatients() {
      try {
        const patientsSnapshot = await firestore.collection("patients").get();
        this.patients = patientsSnapshot.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
      } catch (error) {
        console.error("Error fetching patients from Firebase:", error);
      }
    },
    async sendMessage() {
      if (this.messageInput.trim() === '') {
        return;
      }

      // Add doctor message
      this.messages.push({
        sender: 'doctor',
        text: this.messageInput
      });
      this.scrollToBottom();

      // Reset input
      this.messageInput = '';

      // Set loading state
      this.loading = true;

      // Call the requestAssistantResponse function with the current conversation
      await this.requestAssistantResponse();

      // Reset loading state
      this.loading = false;
    },

    async requestAssistantResponse() {
      //const apiUrl = "http://127.0.0.1:5001/virtualpatientgpt/us-central1/api";
      //const apiUrl = "https://us-central1-virtualpatientgpt.cloudfunctions.net/api";
      const apiUrl = "https://streamtest-kjrpu6jybq-uc.a.run.app";

      // Map the messages to the required format
      const formattedMessages = this.messages.slice(1).map(message => {
        return {
          role: message.sender === 'doctor' ? 'user' : 'assistant',
          content: message.text
        };
      });

      const requestData = {
        model: "gpt-3.5-turbo",
        messages: formattedMessages,
        stream: true,
      };

      let finishReason = null;

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let buffer = "";

        // Create a new patient message and get its index
        const newMessageIndex = this.messages.push({
          sender: 'patient',
          text: '',
        }) - 1;

        let loopCounter = 0;

        // Continue reading until finish_reason is "stop"
        while (finishReason !== "stop") {
          loopCounter++;
          // Break the loop if the counter exceeds the threshold
          if (loopCounter > 5000) {
            console.warn("Loop counter exceeded 5000 iterations. Breaking the loop.");
            break;
          }
          const { done, value } = await reader.read();
          console.log("Stream complete", done);

          buffer += decoder.decode(value, { stream: true });

          if (buffer.includes("\n")) {
            const chunk = buffer.slice(0, buffer.indexOf("\n"));
            var newData = chunk.toString().split("data: ")[1];
            if (newData) {
              const jsonData = JSON.parse(newData);
              const partialMessage = jsonData.choices[0].delta.content;
              finishReason = jsonData.choices[0].finish_reason;
              console.log(partialMessage);

              // Update the patient message in the chat
              if (partialMessage) {
                this.messages[newMessageIndex].text += partialMessage;
                this.$forceUpdate(); // Force update the component to reflect the change in the chat
                this.scrollToBottom();
              }
            }
            buffer = buffer.slice(buffer.indexOf("\n") + 1);
          }
        }
      } catch (error) {
        console.error("Error fetching requestAssistantResponse data:", error);
      }
    },
  },
  mounted() {
    this.changePatient();
  },

};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  background-color: #363636;
  color: white;
  padding: 1rem;
  text-align: center;
}

.chat-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  background-image: url('@/assets/medpattern.png');
  background-repeat: repeat;
  background-size: 35%;
}

.chat-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.message-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.message-doctor {
  background-color: #3e8ed0;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  align-self: flex-end;
  max-width: 80%;
}

.message-patient {
  background-color: #48c774;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  align-self: flex-start;
  max-width: 80%;
}

.message-instructions {
  text-align: center;
  font-weight: bold;
  color: #4a4a4a;
}

.footer {
  background-color: #f5f5f5;
  padding: 1rem;
  text-align: center;
}

.send-input-bar {
  display: flex;
  gap: 1rem;
}

.message-container:nth-child(1) .message-doctor,
.message-container:nth-child(2) .message-doctor {
  display: none;
}

.select-patient {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border-bottom: 2px solid #363636;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-gif {
  width: 40px;
  height: 40px;
  border: 1px solid #363636;
  border-radius: 20px;
}
</style>